import styled from 'styled-components';

export const Container = styled.div`
  background: transparent;
  padding: 2em 0.8em 1em;

  h3 {
    margin: 0 0 2em;
    font-size: 0.9em;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
    color: ${props => props.theme.textColors['dark-500']};
  }
`;

interface LogoProps {
  size?: number;
}

export const Logo = styled.div<LogoProps>`
  svg,
  img {
    width: auto;
    height: ${props => (props.size ? props.size : 35)}px;
  }
`;
