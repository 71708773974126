import * as React from 'react';
import * as Grid from 'react-grid-system';
import { Container, Logo } from './PoweredBy.styles';
import { MasterCardLogo, PayPalLogo, VisaLogo } from './icons';

interface PoweredByProps {
  showTitle?: boolean;
}

export const PoweredBy: React.FC<PoweredByProps> = ({ showTitle = true }) => (
  <Container>
    <Grid.Container>
      <Grid.Row nogutter>
        <Grid.Col>
          {showTitle && <h3>Powered By</h3>}

          <Grid.Row nogutter align="center">
            <Grid.Col
              xs={12}
              md={3}
              style={{ textAlign: 'center', marginBottom: '1.5rem' }}
            >
              <Logo size={20}>
                <img
                  alt="Discover Card"
                  src={require('./icons/discover.png')}
                />
              </Logo>
            </Grid.Col>
            <Grid.Col
              xs={12}
              md={3}
              style={{ textAlign: 'center', marginBottom: '1.5rem' }}
            >
              <Logo size={25}>
                <VisaLogo />
              </Logo>
            </Grid.Col>
            <Grid.Col
              xs={12}
              md={3}
              style={{ textAlign: 'center', marginBottom: '1.5rem' }}
            >
              <Logo size={38}>
                <MasterCardLogo />
              </Logo>
            </Grid.Col>
            <Grid.Col
              xs={12}
              md={3}
              style={{ textAlign: 'center', marginBottom: '1.5rem' }}
            >
              <Logo size={25}>
                <PayPalLogo />
              </Logo>
            </Grid.Col>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  </Container>
);
