import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useShop } from '@app/lib/shop';

interface PageHeadProps {
  title?: string;
  noIndex?: boolean;
}

export const PageHead: React.FC<PageHeadProps> = ({
  title = '',
  noIndex = false,
}) => {
  const shop = useShop();
  const router = useRouter();
  const canonicalPath = shop.canonical + router.asPath;
  const [isInactivePage, setIsInactivePage] = useState<boolean>(false);
  const inactivePageMessage = "Don't forget this! 🌙";

  useEffect(() => {
    window.onblur = () => {
      setIsInactivePage(true);
    };
    window.onfocus = () => {
      setIsInactivePage(false);
    };
  }, []);

  return (
    <Head>
      <title>
        {isInactivePage ? inactivePageMessage : title || shop.seo.pageTitle} |{' '}
        {shop.title}
      </title>

      <link rel="canonical" href={canonicalPath} />
      <meta property="og:url" content={canonicalPath} />
      <meta
        property="og:title"
        content={`${title || shop.seo.pageTitle} | ${shop.title}`}
      />
      {noIndex ? <meta name="robots" content="noindex" /> : null}
    </Head>
  );
};
