import * as React from 'react';
import Image from 'next/image';
import { Container, Emblem, Description, Copy } from './CarbonFree.styles';

export const CarbonFree: React.FC = () => (
  <Container>
    <Emblem>
      <Image
        height={123}
        width={150}
        src={'/assets/images/carbon-free.png'}
        alt="Carbon Free Badge"
      />
    </Emblem>
    <Description>
      <h2>
        We&lsquo;ve joined Carbon<span>free</span>&reg;!
      </h2>
      <Copy>
        We love our planet which is why we joined the Carbonfree&reg; program.
        Every year we buy carbon offsets in assurance that we remain carbon
        neutral!
      </Copy>
    </Description>
  </Container>
);

export default CarbonFree;
