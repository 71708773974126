import styled from 'styled-components';
import { media } from '@app/styles';

export const Container = styled.div`
  margin: 3.5em 0 1em 0;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left !important;

  ${media.lg`
    flex-direction: row;
    width: 800px;
  `}
`;

export const Emblem = styled.div`
  display: block;
  width: 150px;
  height: 123px;
`;

export const Description = styled.div`
  margin: 2em 1em 1em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;

  h2 {
    ${(props: any) => props.theme.fonts.secondary}
    font-size: 2rem;
    font-weight: 600;
    margin: 0 0 1rem 0;
    padding: 0;

    span {
      font-style: italic;
      font-weight: 500;
    }
  }

  ${media.lg`
    margin: 1em 0 1em 2em;
    width: 600px;
    text-align: left;
  `}
`;

export const Copy = styled.div`
  font-size: 1.1rem;
  font-weight: 350;
  line-height: 1.6rem;
  text-align: center;

  ${media.lg`
    text-align: left;
  `}
`;
