import styled from 'styled-components';
import { media } from '@app/styles';

interface WrapperProps {
  reviewsPage: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  background-color: ${props =>
    props.reviewsPage
      ? props.theme.bgColors['light-blue-200']
      : props.theme.bgColors['light-100']};
  border-top: 1px solid #fff1e4;
  border-bottom: 1px solid #fff1e4;
  padding-top: 3rem;
  padding-bottom: 3rem;
  text-align: center;

  h2 {
    ${(props: any) => props.theme.fonts.secondary}
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 1.5rem;
    margin-top: 0;
    text-align: center;
  }

  h4 {
    font-size: 1.1rem;
    font-weight: 400;
    line-height: 1.6;
    width: 83.33333%;
    margin: 1rem auto 3rem auto;
  }

  section {
    padding: 1.25rem 2rem;
    background-color: ${props =>
      props.reviewsPage ? '#fff' : props.theme.bgColors['light-blue-200']};
    border-radius: 0.5rem;
    min-height: 100%;
    text-align: left;

    h2 {
      font-size: 1.3rem;
      text-align: left;
      margin: 0;
    }

    p {
      margin-top: 1rem;
      margin-bottom: 1rem;
      font-size: 0.9rem;
      line-height: 1.5;
      font-weight: 400;
    }
  }

  ${media.lg`
    h2 {
      line-height: 1.5;
    }
  `}
`;

const OkIcon = require('./icons/ok.svg');
export const Verified = styled.div`
  background: transparent url(${OkIcon}) center left no-repeat;
  background-size: 16px 16px;
  padding: 0.3rem 1.5rem 0;
  margin-bottom: 0.5rem;
  margin-top: 0.8rem;
  font-weight: 300;
  font-size: 0.85rem;
  color: #000000;
  text-transform: uppercase;
  -webkit-letter-spacing: 0.05em;
  -moz-letter-spacing: 0.05em;
  -ms-letter-spacing: 0.05em;
  letter-spacing: 0.05em;
`;

export const Stars = styled.ul`
  list-style: none;
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
  padding: 0;
  overflow: hidden;
  li {
    float: left;
    margin-right: 0.2rem;
    img {
      width: 0.9rem;
    }
  }
`;

export const Branding = styled.div`
  background: white;
  border-radius: 0.5rem;
  margin-bottom: 1.4rem;
  cursor: pointer;
`;

export const Percentage = styled.div`
  border-right: 1px solid #eee;
  text-align: center;
  padding: 5px;

  > h2 {
    font-size: 2rem;
    font-weight: 600;
    color: #1ea55e;
    margin: 0;

    > span {
      font-size: 1.5rem;
    }
  }

  > h4 {
    display: block;
    font-size: 0.6rem;
    font-weight: normal;
    line-height: 1.4;
    color: #616161;
    margin: 0 auto;
  }

  > h5 {
    display: block;
    font-size: 0.7em;
    font-weight: 700;
    line-height: 1.4;
    margin: 2px 0 5px 0;
  }
`;

export const GoogleImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  img {
    width: 120px;
  }

  ${media.lg`
    height: 93px;
  `}
`;
