import styled from 'styled-components';
import { media } from '@app/styles';

export const Container = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 3em 0 2em;

  p {
    margin-top: 0;
    font-size: 1em;
    font-style: italic;
    font-weight: 100;
    color: ${props => props.theme.textColors['dark-600']};
    text-align: center;
  }
`;

export const Blockquote = styled.blockquote`
  position: relative;

  ${media.lg`
    max-width: 720px;
  `}
`;

export const Quote = styled.h2`
  ${(props: any) => props.theme.fonts.secondary}
  font-size: 1.5rem;
  font-weight: 500;
  padding: 1rem 0 0;
  line-height: 1.57;
  text-align: center;
  position: relative;
`;
