export const PayPalLogo = (): JSX.Element => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 526.775 140.375">
    <path
      d="M63.213.009H22.24a5.693 5.693 0 00-5.626 4.806L.043 109.88c-.33 2.072 1.276 3.942 3.379 3.942h19.56a5.692 5.692 0 005.626-4.81l4.47-28.338a5.687 5.687 0 015.62-4.812h12.97c26.99 0 42.566-13.06 46.635-38.942 1.833-11.323.077-20.22-5.225-26.45C87.255 3.627 76.926.009 63.213.009zm4.727 38.373C65.7 53.085 54.466 53.085 43.604 53.085h-6.183l4.338-27.457a3.413 3.413 0 013.373-2.882h2.833c7.4 0 14.38 0 17.986 4.217 2.15 2.517 2.81 6.255 1.989 11.42M185.688 37.91h-19.62a3.42 3.42 0 00-3.374 2.881l-.868 5.488-1.372-1.99c-4.248-6.164-13.72-8.225-23.174-8.225-21.681 0-40.2 16.421-43.806 39.457-1.875 11.49.79 22.479 7.309 30.14 5.979 7.046 14.534 9.982 24.713 9.982 17.47 0 27.158-11.233 27.158-11.233l-.875 5.452a3.413 3.413 0 003.367 3.954h17.673a5.694 5.694 0 005.626-4.81l10.604-67.155a3.4 3.4 0 00-3.362-3.942zm-27.35 38.186c-1.892 11.21-10.79 18.735-22.135 18.735-5.698 0-10.252-1.828-13.176-5.291-2.9-3.439-4.002-8.334-3.079-13.786 1.767-11.113 10.815-18.883 21.987-18.883 5.572 0 10.101 1.851 13.085 5.343 2.99 3.53 4.175 8.454 3.319 13.882M290.184 37.91h-19.717a5.713 5.713 0 00-4.715 2.498L238.56 80.464 227.033 41.97a5.71 5.71 0 00-5.464-4.062h-19.375c-2.355 0-3.99 2.3-3.241 4.517l21.717 63.733-20.417 28.823c-1.607 2.27.01 5.393 2.786 5.393h19.692a5.683 5.683 0 004.679-2.445l65.578-94.659c1.57-2.265-.042-5.362-2.804-5.362"
      fill="#283b82"
    />
    <path
      d="M355.462.009h-40.978a5.693 5.693 0 00-5.62 4.806L292.293 109.88a3.41 3.41 0 003.367 3.942h21.029a3.983 3.983 0 003.93-3.367l4.702-29.781a5.687 5.687 0 015.62-4.812h12.965c26.995 0 42.566-13.06 46.64-38.942 1.84-11.323.073-20.22-5.23-26.45C379.5 3.627 369.176.009 355.462.009zm4.728 38.373c-2.235 14.703-13.467 14.703-24.336 14.703h-6.176l4.343-27.458a3.401 3.401 0 013.366-2.881h2.834c7.394 0 14.379 0 17.985 4.217 2.151 2.516 2.804 6.255 1.984 11.42M477.933 37.91h-19.61a3.398 3.398 0 00-3.367 2.881l-.869 5.488-1.377-1.99c-4.247-6.164-13.714-8.225-23.167-8.225-21.683 0-40.194 16.421-43.802 39.457-1.868 11.49.785 22.479 7.304 30.14 5.991 7.046 14.534 9.982 24.712 9.982 17.47 0 27.158-11.233 27.158-11.233l-.875 5.452a3.416 3.416 0 003.38 3.954h17.667a5.692 5.692 0 005.62-4.81l10.61-67.155c.323-2.067-1.282-3.942-3.385-3.942zm-27.35 38.186c-1.882 11.21-10.79 18.735-22.137 18.735-5.686 0-10.251-1.828-13.175-5.291-2.899-3.439-3.99-8.334-3.079-13.786 1.78-11.113 10.814-18.883 21.988-18.883 5.571 0 10.1 1.851 13.084 5.343 3.001 3.53 4.187 8.454 3.318 13.882M501.064 2.891L484.246 109.88a3.41 3.41 0 003.368 3.942h16.906a5.684 5.684 0 005.626-4.81L526.73 3.951a3.413 3.413 0 00-3.367-3.948H504.43a3.421 3.421 0 00-3.366 2.887"
      fill="#469bdb"
    />
  </svg>
);
