import * as React from 'react';
import * as Grid from 'react-grid-system';
import { CarbonFree } from '@app/components/CarbonFree';
import { Container, Blockquote, Quote } from './Testimonial.styles';

export const Testimonial: React.FC = () => (
  <Container>
    <Grid.Container>
      <Grid.Row>
        <Grid.Col>
          <Blockquote>
            <Quote>
              &quot;I bought this as special gift for my husband and he
              absolutely LOVED it! He was super impressed by the quality of the
              Moon Map and how unique it was. He couldn&rsquo;t stop talking
              about it!&quot;
            </Quote>
            <p>- Elizabeth M.</p>
          </Blockquote>
        </Grid.Col>
      </Grid.Row>
      <CarbonFree />
    </Grid.Container>
  </Container>
);
